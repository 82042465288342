<template>
    <h1>
        Album
    </h1>
    <div class="albumList">
        <button
            class="album"
            v-for="album in albumList"
            :key="album.id"
            v-on:click="redirect(album.id)">
            <div class="icon">
                {{album.title[0]}}
            </div>
            <div class="text">
                {{album.title}}
            </div>
        </button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        oncreated(){
            //album list
            axios.get('/api/v1/album/list/')
            .then(response => {
                if(response.data.success === true){
                    this.albumList = [];
                    this.albumList.push(...response.data.value);
                }else{
                    if(response.data.error){
                        console.log(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        redirect(id){
            this.$router.push(`/album/${id}`);
        }
    },
    data(){
        return {
            albumList: [],
        }
    },
    created(){
        this.oncreated();
    }
}
</script>

<style scoped>
    .albumList{
        display: flex;
        flex-direction: row;
    }
    .album{
        display: inline-block;
        margin: 15px;
        box-shadow: 5px 5px 7px var(--dark-bg);
        border-radius: 5px;
        cursor: pointer;
        /* background-color: var(--dark-bg);
        color: #fff; */
    }
    .icon{
        display: inline-flex;
        min-width: 120px;
        min-height: 120px;
        align-items: center;
        justify-content: center;
        font-size: 50pt;
    }
    .text{
        width: 100%;
        padding: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: '..';
    }
</style>